<template>
    <div ref="videoContainer" class="shadow-lg mx-auto max-w-full size">
        <div v-if="isLoading" class="circular-loader">
          <svg viewBox="25 25 50 50">
              <circle r="20" cy="50" cx="50"></circle>
            </svg>
        </div>
        <video id="video" ref="videoPlayer" class="video-player" :poster="selectedVideo.image_url" @waiting="onBuffering" @playing="onPlaying" autoplay></video>
        <div class="video-overly-info" style="z-index: 1;">
            <h3 style="color: rgba(2,6,1,.1) !important;">{{ stamp }}</h3>
            <h3 style="color: rgba(2,6,1,.1) !important;">www.nearpeer.org</h3>
        </div>
        <div class="video-marquee" v-if="userStamp">
            <span class="text marquee-text">{{ userInfo }}</span>
        </div>
    </div>
</template>

<script>
import courseService from "../../services/course-service";
import customStorageService from "../../../app-services/custom-storage-service";
let player;
let user = {};
let obj = {};
let percent = 0
let moment = require("moment");
export default {
    props: [
        "selectedVideo",
        "userData",
        "currentCourse",
        "selectedModule",
        "selectedChapter",
        "videoLastStat",
        "webviewPlayer"
    ],
    data() {
        return {
            manifestUrl: "",
            posterUrl: '',
            stamp: "",
            userInfo: "",
            userStamp: true,
            isLoading: true,
        }

    },
    created() {
        this.manifestUrl = `https://video.nearpeer.org${this.selectedVideo.url_mpd}`
    },
    mounted() {
        const shaka = require('shaka-player/dist/shaka-player.ui.js');
        player = new shaka.Player(this.$refs.videoPlayer);
        const ui = new shaka.ui.Overlay(
            player,
            this.$refs.videoContainer,
            this.$refs.videoPlayer,
        );
        const config = {
          'controlPanelElements': ['play_pause', 'time_and_duration', 'spacer', 'mute' , 'fullscreen', 'overflow_menu'], // List of controls you want to keep
          'overflowMenuButtons': ['quality', 'playback_rate'],
        };
        ui.configure(config);
        ui.getControls();

        const controlPanel = ui.getControls().getControlsContainer();
        controlPanel.classList.add('custom-control-panel');
        this.startLoading(true)
        player
            .load(this.manifestUrl)
            .then(() => {
                this.isLoading = false;
                // This runs if the asynchronous load is successful.
                console.log('The video has now been loaded!');
                
                this.setVideoLastTime()
            })
            .catch(this.onError); // onError is executed if the asynchronous load fails.

        this.initializations();
        document.addEventListener("message", this.mobileWebviewEventHandler)
        window.addEventListener("beforeunload", this.onClose);
    },
    methods: {
        onBuffering() {
          this.startLoading(true)
            this.isLoading = true; // Show spinner on buffering
        },
        onPlaying() {
            this.isLoading = false; // Hide spinner when video starts playing
        },
        onError(error) {
            console.error('Error code', error.code, 'object', error);
        },
        initializations() {
            user = customStorageService.getUser();
            if (user) {
                let date = new Date();
                this.userInfo =
                    user.email +
                    " , " +
                    user.phone +
                    " , " +
                    moment(date).format("MM/DD/YYYY hh:mm");
                let monthNames = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ];
                this.stamp = monthNames[date.getMonth()] + "-" + date.getFullYear();
            }
        },
        async setVideoStats() {
          if (user) {
            let currenVideotTime = this.$refs.videoPlayer.currentTime;
            const duration = this.$refs.videoPlayer.duration;
            obj = {
              video_time: currenVideotTime,
              event_type: "",
              video_active_watched_time: 0,
              video_total_duration: duration,
              course: {
                id: this.currentCourse.id,
                name: this.currentCourse.name
              },
              module: {
                id: this.selectedModule.id,
                name: this.selectedModule.name
              },
              chapter: {
                id: this.selectedChapter ? this.selectedChapter.id : null,
                name: this.selectedChapter ? this.selectedChapter.name : null
              },
              video: {
                id: this.selectedVideo.id,
                name: this.selectedVideo.name
              },
              user: {
                id: user.id,
                name: user.full_name,
                email: user.email
              }
            };
          }
        },
        sendVideoStats() {
          if (obj.video && user) {
            obj.live_session_id = this.selectedVideo.live_session_id;
            courseService.saveVideoStat(obj).then(res => {});
          }
        },

        mobileWebviewEventHandler(message) {
          if (message.data.message.includes("close-video")) {
            this.setVideoStats();
            obj.event_type = "close_video";
            obj.screen_left_reason = "Close Tab";
            this.sendVideoStats();
          }
        },

        onClose(event) {
          this.setVideoStats();
          obj.event_type = "close_video";
          obj.screen_left_reason = "Close Tab";
          this.sendVideoStats();
          return true;
        },
        changeVideo() {
          obj.event_type = "close_video";
          obj.screen_left_reason = "Video Change";
          this.sendVideoStats();
        },
        startLoading(start) {
          if(start) {
            percent = 0;
            this.isLoading = true;
          }
          try {
            setTimeout(() => {
              if(percent < 98) {
                percent+= 2;
                this.$refs.videoContainer.querySelector('.circular-loader').setAttribute('data-percent', `${percent}%`);
                this.startLoading()
              }
            }, 250)
          } catch (error) {
            console.log('-')
          }
          
        },
        async setVideoLastTime() {
          this.isFirstTime = true;
          if ((this.selectedVideo || {}).trackData) {
            if (
              (((this.selectedVideo || {}).trackData || {}).videoLastStat || {})
                .videoTime > 0
            ) {

              this.$refs.videoPlayer.currentTime = this.selectedVideo.trackData.videoLastStat.videoTime;
            }
          } else {
            if (!this.webviewPlayer && user && user.id) {
              setTimeout(() => {
                this.setVideoLastTime();
              }, 1000);
            } else {
              if (this.videoLastStat) {
                this.$refs.videoPlayer.currentTime = this.videoLastStat.videoTime;
              }
            }
          }
          this.$refs.videoPlayer.play();
        },

    },
    beforeUnmount() {
      this.setVideoStats();
      obj.event_type = "close_video";
      obj.screen_left_reason = "Close Tab";
      this.sendVideoStats();
    },
    async beforeDestroy() {
      this.setVideoStats();
      obj.event_type = "close_video";
      obj.screen_left_reason = "Close Tab";
      this.sendVideoStats();
      await player.destroy();
    },
    watch: {
        selectedVideo: async function (newVal, oldVal) {
          this.startLoading(true)
            this.manifestUrl = `https://video.nearpeer.org${newVal.url_mpd}`
            this.isLoading = true;
            player
                .load(this.manifestUrl)
                .then(() => {
                    this.isLoading = false;
                    // This runs if the asynchronous load is successful.
                    this.setVideoLastTime();
                    console.log('The video has now been loaded!');
                })
                .catch(this.onError); // onError is executed if the asynchronous load fails.
        }
    }
};
</script>

<style>
@import '../../../../../node_modules/shaka-player/dist/controls.css';
/* Shaka player CSS import */

.shaka-controls-container[shown=true] .shaka-bottom-controls {
    opacity: 1
}

.shaka-bottom-controls {
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black tint with 50% opacity */
  padding: 10px; /* Optional: Add some padding around the controls */
  border-radius: 8px; /* Optional: Add rounded corners */
  margin-bottom: 10px;
  transition: opacity .6s cubic-bezier(.4,0,.6,1);
}

.shaka-scrim-container {
  display: none;
}

.size {
    height: 100%;
}

.video-player {
    width: 100%;
}
.video-marquee {
  padding: 5px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.marquee-text {
  z-index: 90;
}

.video-marquee .text {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  color: rgba(0, 0, 0, 0.2);
  font-size: 18px;
  text-align: center;
  line-height: 1.6em;
  position: absolute;
  left: 0;
  top: -30px;
  animation: animTopBottom 180s 60s linear infinite;
  -webkit-animation: animTopBottom 180s 60s linear infinite;
  -moz-animation: animTopBottom 180s 60s linear infinite;
}

.circular-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  z-index: 2;
  transform: translate(-50%, -50%);
}

.circular-loader:before {
  content: attr(data-percent);
  position: absolute;
  top: 55%;
  left: 55%;
  width: 50px;
  height: 50px;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsla(0, 97%, 59%, 0.84);
  transform: translate(-55%, -55%);
  border-radius: 50%;

}

.circular-loader svg {
  width: 5.25em;
  transform-origin: center;
  animation: rotate4 2s linear infinite;
}

.circular-loader circle {
  fill: none;
  stroke: hsl(0, 97%, 59%);
  stroke-width: 2;
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke-linecap: round;
  animation: dash4 1.5s ease-in-out infinite;
}

.shaka-spinner-container {
    display: none !important;
}

@keyframes rotate4 {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash4 {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dashoffset: -125px;
  }
}


@keyframes animTopBottom {
  0% {
    top: 100%;
  }
  3% {
    top: -100%;
  }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
  .video-marquee .text {
    font-size: 12px !important;
    animation: animTopBottom 290s 60s linear infinite !important;
    -webkit-animation: animTopBottom 290s 60s linear infinite !important;
    -moz-animation: animTopBottom 290s 60s linear infinite !important;
  }
}
</style>